import { Parallax } from 'react-scroll-parallax'
import React from 'react'
import styled from 'styled-components'

import AboutPoints from 'components/aboutPoints'
import {
  InnerContainer,
  Container,
  Subtitle,
  Title,
  Info,
} from 'components/common'
import { colors, media } from '../variables'

const GreyContainer = styled.div`
  position: relative;
  padding-top: 5.5rem;
  padding-bottom: 4rem;
  background-color: ${colors['lightest-grey']};
  color: ${colors['dark-blue']};
  width: 100%;

  ${Title} {
    font-size: 5rem;
    margin-top: 0;
    font-weight: 200;
    text-align: right;
    width: 50%;

    ${media.mdMax`
      font-size: 3rem;
      width: 100%;
      max-width: 320px;
      margin-bottom: 30px;
    `}
  }

  ${Info} {
    color: ${colors['dark-blue']};
    width: ${100 / 3}%;
    margin-bottom: 3rem;

    ${media.mdMax`
      width: 100%;
      margin-bottom: 0;
      text-align: left;
    `}
  }

  ${InnerContainer} {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;

    ${media.mdMax`
      flex-direction: column;
    `}
  }
`

const BlueContainer = styled.div`
  padding-top: 10rem;
  padding-bottom: 10rem;
  background-color: ${colors['sky-blue']};
  color: ${colors.white};
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1;

  ${Title} {
    width: ${(100 / 6) * 5}%;
    color: ${colors.white};
    max-width: 50rem;
    font-size: 2.25rem;
    margin-left: auto;
    margin-right: auto;
    font-weight: 200;

    ${media.mdMax`
      width: 100%;
      max-width: 100%;
    `}
  }
`

const PointsContainer = styled(Container)`
  ${Info} {
    margin-bottom: 2rem;
  }
`

const Rectangle = styled.div`
  position: absolute;
  right: -20px;
  top: 0;
  width: 200px;
  height: 200px;
  right: 30px;
  background: ${colors['dark-blue']};
  transform: skew(-10deg, 0deg);

  ${media.lgMax`
    display: none;
  `}
`

const lineBreak = styled.br`
  display: none;
  ${media.xsMax`
    display: block;
  `}
`

const About = () => (
  <>
    <PointsContainer id="about">
      <InnerContainer>
        <Subtitle>Nosotros</Subtitle>
        <Title>Somos Up 180</Title>
        <Info>
          Y hacemos que las empresas tengan más éxito desarrollando productos
          hechos a su medida.
        </Info>
        <AboutPoints />
      </InnerContainer>
    </PointsContainer>
    <GreyContainer id="about-section-two">
      <Parallax
        y={[-60, 25]}
        styleOuter={{ position: 'absolute', left: 0, right: 0 }}
        styleInner={{
          position: 'relative',
          width: '100%',
          height: '40rem',
          maxHeight: '400px',
          overflow: 'hidden',
        }}>
        <Rectangle />
      </Parallax>
      <InnerContainer>
        <Title>Aunque tenemos nuestro proceso definido</Title>
        <Info>
          El camino hacia la innovación es diferente para cada proyecto, por lo
          que adaptamos nuestras metodologías y estilos de comunicación para
          satisfacer sus necesidades.
        </Info>
      </InnerContainer>
    </GreyContainer>
    <BlueContainer>
      <InnerContainer>
        <Title>
          Tiempo de respuesta, Conocimiento de la solución Compromiso con el
          resultado y satisfacción.
        </Title>
      </InnerContainer>
    </BlueContainer>
  </>
)

export default About
