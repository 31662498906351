import React from 'react'
import styled, { css } from 'styled-components/macro'
import { colors, media } from '../variables'

const HeroTextContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Text = styled.h1`
  font-size: 3rem;
  color: ${colors.white};
  text-transform: uppercase;
  font-weight: 300;

  ${media.md`
    font-size: 5rem;
  `}

  ${media.sm`
    font-size: 4rem;
  `}

  ${props => css`
    transform: translate3d(${props.left / 3}%, 0, 0);

    ${media.xs`
      transform: translate3d(${props.left / 2}%, 0, 0);
    `}

    ${media.md`
      transform: translate3d(${props.left}%, 0, 0);
    `}
  `}
`

const BlueText = styled.span`
  color: ${colors['sky-blue']};
`

const HeroText = () => (
  <HeroTextContainer>
    <Text left={-30}>
      El <BlueText>producto</BlueText>
    </Text>
    <Text left={-10}>que esperas</Text>
    <Text left={30}>
      con la <BlueText>solución</BlueText>
    </Text>
    <Text left={-15}>que se necesita</Text>
  </HeroTextContainer>
)

export default HeroText
