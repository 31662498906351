import React, { useState } from 'react'
import Icon from '../images/siguiente.svg'
import ContactForm from './contactForm'
import HoverIconContainer from './hoverIconContainer'
import Close from '../images/cerrar.svg'
import CloseHover from '../images/cerrar-hover.svg'
import {
  StyledContainer,
  StyledContainerLeft,
  StyledContainerRight,
  ContentIconClose,
  StyledContentText,
  InfoContent,
  SendContent,
  SendText,
  StyledProyectSend,
  IconNext,
  ContactText,
} from './common'

const ContactUs = ({ isOpen, closeContact }) => {
  const [isOpenForm, setOpenForm] = useState(null)

  const closeForm = () => {
    setOpenForm(false)
    closeContact()
  }

  return (
    <>
      <StyledContainer isOpen={isOpen}>
        <StyledContainerLeft onClick={closeContact} />
        <StyledContainerRight>
          <ContentIconClose isOpen={isOpen}>
            <HoverIconContainer
              Icon={Close}
              HoverIcon={CloseHover}
              closeMenu={closeForm}
            />
          </ContentIconClose>
          <StyledContentText>
            <InfoContent>
              Confía en nosotros para la
              <br />
              solución y encargarte de
              <br />
              operar tu negocio.
            </InfoContent>
            <SendContent>
              <SendText>
                <StyledProyectSend onClick={() => setOpenForm(true)}>
                  ENVÍANOS TU PROYECTO
                </StyledProyectSend>
                <IconNext as={Icon} onClick={() => setOpenForm(true)} />
              </SendText>
              <ContactText>CONTACTANOS</ContactText>
            </SendContent>
          </StyledContentText>
        </StyledContainerRight>
      </StyledContainer>

      <ContactForm isOpenForm={isOpenForm} closeForm={closeForm} />
    </>
  )
}

export default ContactUs
