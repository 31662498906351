import React, { useState } from 'react'
import styled from 'styled-components'

import { InnerContainer, Container, Button } from 'components/common'
import ContactUs from './contactUs'
import VisitUs from './visitUs'

import { media } from '../variables'

const StyledInnerContainer = styled(InnerContainer)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-top: 120px;
`

const StyledButton = styled(Button)`
  font-size: ${props => (props.small ? '120px' : '135px')};
  font-family: 'Bebas Neue';
  line-height: 1.2;
  color: ${props => (props.isActive ? '#294b52' : '#ececec')};
  text-decoration: none;
  transition: color 300ms ease-in-out;
  padding: 0;

  &:hover {
    color: #294b52;
  }

  ${media.xlMax`
    font-size: ${props => (props.small ? '115px' : '135px')};
    padding: 0.5rem 0;
  `}

  ${media.lgMax`
    font-size: ${props => (props.small ? '88px' : '100px')};
  `}

  ${media.mdMax`
    font-size: ${props => (props.small ? '55px' : '60px')};
    line-height: 1;
    padding: 1rem 2rem;
  `}

  ${media.xsMax`
    font-size: ${props => (props.small ? '40px' : '45px')};
  `}
`

const Creators = styled.a`
  position: absolute;
  bottom: 30px;
  z-index: 10;
  font-size: 14px;
  font-weight: bold;
  left: 50%;
  transform: translateX(-50%);
  text-decoration: none;

  &,
  &:hover,
  &:focus {
    color: #294b52;
  }

  &:hover {
    text-decoration: underline;
  }
`

const Contact = () => {
  const [activeSection, setActiveSection] = useState(null)

  return (
    <>
      <Container id="contact">
        <StyledInnerContainer>
          <StyledButton
            onClick={() =>
              setActiveSection(activeSection === 'contact' ? null : 'contact')
            }>
            Contáctanos
          </StyledButton>
          <StyledButton as="a" href="tel:+525544229219">
            +52 5544229219
          </StyledButton>
          <StyledButton
            onClick={() =>
              setActiveSection(activeSection === 'location' ? null : 'location')
            }>
            Visítanos
          </StyledButton>
          <StyledButton as="a" href="mailto:contacto@up180.mx" bold>
            contacto@up180.mx
          </StyledButton>
          <Creators
            href="https://blissddm.xyz"
            rel="noopener noreferrer"
            target="_blank">
            Created By Bliss DDM
          </Creators>
        </StyledInnerContainer>
      </Container>
      <ContactUs
        isOpen={activeSection === 'contact'}
        closeContact={() => setActiveSection(null)}
      />
      <VisitUs
        isOpen={activeSection === 'location'}
        closeContact={() => setActiveSection(null)}
      />
    </>
  )
}

export default Contact
