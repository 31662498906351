import React from 'react'
import styled, { keyframes } from 'styled-components'

import MouseIcon from 'images/mouse.svg'
import { media } from '../variables'
import { InnerContainer } from './common'

const HeroFooterContainer = styled(InnerContainer)`
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: right;
  padding-right: 100px;

  ${media.mdMax`
    padding-right: 0;
  `}

  ${media.smMax`
    padding-right: 20px;
    padding-bottom: 0;
  `}
`

const HeroFooterLink = styled.a`
  display: inline-block;
  font-weight: ${props => (props.bold ? 600 : 300)};
  color: white;
  text-decoration: none;
  line-height: 2;
  font-size: 16px;
`

const scroll = keyframes`
  0% {
    transform: translateY(-10px);
  }
  30% {
    transform: translateY(-50px);
  }
`

const StyledMouse = styled(MouseIcon)`
  width: 20px;
  position: absolute;
  bottom: 6.5rem;
  left: 50%;
  transform: translateX(-50%);

  #wheel {
    animation: ${scroll} 2s ease infinite;
  }

  ${media.mdMax`
    bottom: 10rem;
  `}

  ${media.smMax`
    bottom: 6.5rem;
  `}
`

const BlissDDM = styled.a`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 4rem;
  color: white;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }

  ${media.mdMax`
    display: none;
  `}
`

const HeroFooter = () => (
  <HeroFooterContainer>
    <HeroFooterLink href="mailto:contacto@up180.com" bold>
      contacto@up180.com
    </HeroFooterLink>
    <br />
    <HeroFooterLink href="tel:+525544229219">+52 5544229219</HeroFooterLink>
    <StyledMouse />
    <BlissDDM href="https://blissddm.xyz/" target="_blank">
      Created by Bliss DDM
    </BlissDDM>
  </HeroFooterContainer>
)

export default HeroFooter
