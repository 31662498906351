import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { InnerContainer, Subtitle, Title, Info } from 'components/common'
import { colors, media } from '../variables'
import IconArrow from '../images/siguiente.svg'
import Input from './input'
import HoverIconContainer from './hoverIconContainer'
import Close from '../images/cerrar.svg'
import CloseHover from '../images/cerrar-hover.svg'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${colors['dark-blue']};
  position: fixed;
  z-index: 999;
  top: 0;
  right: ${props => (props.isOpenForm ? '0' : '-100vw')};
  transition: right 300ms cubic-bezier(1, 0.01, 0.625, 1);
  display: flex;
  justify-content: center;
  align-items: center;

  ${Info} {
    ${media.xsMax`
      font-size: 14px;
      
      margin-bottom: 10px !important;
    `}
  }

  ${Subtitle} {
    padding-top: 0px;
    width: 120px;
    cursor: pointer;

    ${media.mdMax`
      margin-left: 70px;
    `}

    ${media.xsMax`
      margin-bottom: 15px;
    `}
  }

  ${Info} {
    margin-bottom: 30px;
  }

  ${media.mdMax`
    z-index: 1000;
  `}

  ${media.xsMax`
    height: auto;
    overflow: scroll;
    bottom: 0;
    display: inline;
    @media screen and (min-height: 800px) {
      display: flex;
    }
  `}
`

const StyledInnerContainer = styled(InnerContainer)`
  position: relative;
  height: 100vh;
  opacity: ${props => (props.isOpenForm ? 1 : 0)};
  transition: all 800ms;
  padding: 0 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.mdMax`
    padding: 0 40px;
    max-width: 40rem;
  `}

  ${media.xsMax`
    height: ${props => (props.sendMail ? '100vh' : 'auto')};
    padding: 10px 20px 0 20px;
    padding-left: 25px;
  `}
`

const StyledTitle = styled(Title)`
  color: ${colors['lightest-grey']};

  ${media.lgMax`
    font-size: 100px;
  `}

  ${media.mdMax`
    font-size: 60px;
  `}

  ${media.xsMax`
    font-size: 45px;
    margin-top: 8px;
  `}
`

const StyledArrowBack = styled.div`
  position: absolute;
  top: -10px;
  left: -70px;
  transform: rotate(180deg);
  cursor: pointer;

  ${media.mdMax`
    left: 0;
  `}
`

const ContentForm = styled.form`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 0;

  ${media.xsMax`
    flex-direction: column;
  `}
`

const StyledContentSend = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const StyledButton = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  font-family: 'Bebas Neue';
  font-size: 30px;
  color: ${colors.white};
  margin: 0 15px;
  cursor: pointer;

  ${media.mdMax`
    margin: 0 0;
    padding: 0 0;
  `}
`

const StyledIconArrow = styled(IconArrow)`
  margin-left: 20px;
`

const ContentIconClose = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  top: 20px;
  right: 20px;
`

const SuccessText = styled.h1`
  color: ${colors.white};
  font-size: 120px;

  ${media.mdMax`
    font-size: 100px;
  `}

  ${media.xsMax`
    font-size: 50px;
  `}
`
const ContentBack = styled.div`
  position: relative;
`

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

const ContactForm = ({ isOpenForm, closeForm }) => {
  const [sendMail, setSendMail] = useState(false)
  const { register, handleSubmit, errors, clearErrors } = useForm()
  const submitForm = data =>
    fetch('/.netlify/functions/send-email/send-email', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(() => {
      setSendMail(true)
      setTimeout(() => closeForm(), 5000)
    })

  return (
    <Container isOpenForm={isOpenForm}>
      <StyledInnerContainer isOpenForm={isOpenForm} sendMail={sendMail}>
        {!sendMail ? (
          <>
            <ContentBack>
              <StyledArrowBack
                as={IconArrow}
                onClick={() => {
                  closeForm()
                }}
              />
              <Subtitle
                onClick={() => {
                  closeForm()
                }}>
                Regresar
              </Subtitle>
            </ContentBack>
            <StyledTitle>Contáctanos</StyledTitle>
            <Info>
              Hola, envíanos tus datos, cuéntanos de tu proyecto para poder
              comunicarnos contigo y ofrecerte la mejor solución.
            </Info>
            <ContentForm onSubmit={handleSubmit(submitForm)}>
              <Input
                label="Nombre"
                id="name"
                ref={register({
                  required: 'Ingresa tu nombre',
                })}
                error={errors.name}
              />
              <Input
                label="Empresa"
                id="company"
                ref={register({
                  required: 'Ingresa tu empresa',
                })}
                error={errors.company}
              />
              <Input
                label="Correo"
                type="email"
                id="email"
                ref={register({
                  required: 'Ingresa tu correo',
                  validate: value =>
                    validateEmail(value) || 'Provee un correo valido',
                })}
                error={errors.email}
              />
              <Input
                label="Teléfono"
                id="phone"
                ref={register({
                  required: 'Ingresa tu teléfono',
                })}
                error={errors.phone}
              />
              <Input
                textarea
                id="description"
                label="Platícanos de tu proyecto o lo que buscas para tu empresa"
                ref={register({
                  required: '¿Qué necesitas para tu empresa?',
                  minLength: {
                    value: 10,
                    message: 'Ingresa al menos 10 caracteres',
                  },
                })}
                error={errors.description}
              />
              <StyledContentSend>
                <StyledButton
                  type="submit"
                  onClick={() => {
                    clearErrors()
                  }}>
                  Enviar <StyledIconArrow />
                </StyledButton>
              </StyledContentSend>
            </ContentForm>
          </>
        ) : (
          <>
            <ContentIconClose onClick={closeForm}>
              <HoverIconContainer Icon={Close} HoverIcon={CloseHover} />
            </ContentIconClose>
            <SuccessText>
              Gracias! Nos comunicaremos contigo a la brevedad.
            </SuccessText>
          </>
        )}
      </StyledInnerContainer>
    </Container>
  )
}

export default ContactForm
