import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { InnerContainer, Container, Button, Title } from 'components/common'
import { media, colors } from '../variables'

const StyledButton = styled(Button)`
  &:last-child {
    margin-top: 60px;
  }
`

const SectionTitle = styled(Title)`
  color: ${colors['sky-blue']};
  ${media.md`
    display: none;
  `}
`

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  background: linear-gradient(
    115deg,
    ${colors['dark-blue']} 70%,
    ${colors['dark-blue']} 50%,
    ${colors.white} 50%,
    ${colors.white} 100%
  );

  ${media.md`
    max-height: 1200px;
    height: 100vh;
    background: linear-gradient(
      115deg,
      ${colors['dark-blue']} 0%,
      ${colors['dark-blue']} 50%,
      ${colors.white} 50%,
      ${colors.white} 100%
    );
  `}

  ${media.mdMax`
    display: none;
  `}

  ${props =>
    props.mobile &&
    css`
      display: none;

      ul {
        width: 70%;
      }

      ${media.mdMax`
        display: block;
      `}
    `}
`

const StyledInnerContainer = styled(InnerContainer)`
  padding-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const InnerContent = styled.div`
  transition: transform 300ms cubic-bezier(1, 0.01, 0.625, 1),
    opacity 300ms cubic-bezier(1, 0.01, 0.625, 1);

  ${media.mdMax`
    color: ${colors.white};
  `}

  ${media.md`
    float: left;
    top: 50%;
  `}

  ul {
    list-style: none;
    margin-left: 0;

    ${media.mdMax`
      margin-top: 30px;
    `}
  }

  b {
    margin-bottom: 5px;
    display: inline-block;
  }

  p:first-of-type {
    margin-top: 10px;
  }
`

const Process = styled(InnerContent)``
const Tech = styled(InnerContent)`
  ${media.md`
    position: absolute;
    opacity: 0;
  `}
`

const Content = styled.div`
  width: 100%;
  max-width: 550px;
  position: relative;
  overflow: hidden;
  color: ${colors.white};

  ${media.md`
    ${props =>
      props.activeElem === 'process'
        ? css`
            ${Process} {
              transform: translateY(0);
              opacity: 1;
            }

            ${Tech} {
              transform: translateY(200%);
              opacity: 0;
            }
          `
        : css`
            ${Process} {
              transform: translateY(-200%);
              opacity: 0;
            }

            ${Tech} {
              transform: translateY(-50%);
              opacity: 1;
            }
          `}
  `}
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column;

  ${media.mdMax`
    display: none;
  `}
`

const ProcessElem = () => (
  <Process>
    <SectionTitle>Procesos</SectionTitle>
    <ul>
      <li>
        <b>Modelos de negocio</b>
        <p>BPM, Modelo de valor y madurez</p>
      </li>
      <li>
        <b>Implementación de modelos de arquitectura de gobierno</b>

        <p>Gobierno de Datos</p>
        <p>Gobierno de Procesos</p>
        <p>Gobierno de Aplicaciones</p>
      </li>
      <li>
        <b>Re-ingenieria de proceso</b>
      </li>
      <li>
        <b>Automatización de procesos</b>
      </li>
    </ul>
  </Process>
)

const TechElem = () => (
  <Tech>
    <SectionTitle>Tecnología</SectionTitle>
    <ul>
      <li>
        <b>Análisis y diseño de requerimientos</b>
      </li>
      <li>
        <b>Desarrollo de software</b>
      </li>
      <li>
        <b>Implementación de metodologías ágiles</b>
      </li>
      <li>
        <b>Asignación de consultores especializados</b>
      </li>
      <li>
        <b>Fábrica de desarrollo</b>
      </li>
      <li>
        <b>Servicios administrados</b>
        <p>Infraestructura</p>
      </li>
    </ul>
  </Tech>
)

const Activities = () => {
  const [activeElem, setActiveElem] = useState('process')
  return (
    <>
      <StyledContainer>
        <StyledInnerContainer>
          <Content activeElem={activeElem}>
            <ProcessElem />
            <TechElem />
          </Content>
          <Buttons>
            <StyledButton
              onClick={() => setActiveElem('process')}
              isActive={activeElem === 'process'}>
              Procesos
            </StyledButton>
            <StyledButton
              onClick={() => setActiveElem('tech')}
              isActive={activeElem === 'tech'}>
              Tecnología
            </StyledButton>
          </Buttons>
        </StyledInnerContainer>
      </StyledContainer>
      <StyledContainer mobile>
        <StyledInnerContainer>
          <ProcessElem />
        </StyledInnerContainer>
      </StyledContainer>
      <StyledContainer mobile>
        <StyledInnerContainer>
          <TechElem />
        </StyledInnerContainer>
      </StyledContainer>
    </>
  )
}

export default Activities
