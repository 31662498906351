import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import { colors, media } from '../variables'

const onMove = keyframes`
  10%, 90% {
    transform: translateX(-.1rem);
  }
  20%, 80% {
    transform: translateX(.2rem);
  }
  30%, 50%, 70% {
    transform: translateX(-.4rem);
  }
  40%, 60% {
    transform: translateX(.4rem);
  }
`

const StyledInput = styled.input`
  width: 100%;
  max-height: 38px;
  margin: 10px 0;
  border: solid 1px ${colors.white};
  background-color: ${colors.white};
  padding: 5px 10px;
  font-size: 22px;
  color: ${colors.inputGrey};

  ${media.xsMax`
    max-height: 32px;
  `}

  ${props =>
    props.hasError && css`
      animation: ${onMove} 0.5s;
      animation-iteration-count: 1;
    `}
`

const StyledTextArea = styled.textarea`
  width: 100%;
  height: 138px;
  margin-top: 10px;
  padding: 10px 10px;
  font-size: 22px;
  max-height: 100%;
  color: ${colors.inputGrey};

  ${media.xsMax`
    height: auto;
    max-height: 138px;
  `}

  ${props =>
    props.hasError &&
    css`
      animation: ${onMove} 0.5s;
      animation-iteration-count: 1;
    `}
`

const StyledLabel = styled.label`
  width: ${props => (props.textarea ? '100%' : '40%')};
  margin-bottom: 20px;
  font-size: 30px;
  font-family: 'Bebas Neue';
  line-height: 1;
  color: ${colors['sky-blue']};
  position: relative;

  ${media.mdMax`
    width: ${props => (props.textarea ? '100%' : '45%')};
  `}

  ${media.xsMax`
    width: 100%;
    font-size: 23px;
    margin-bottom: 0;
    margin-top: ${props => (props.textarea ? '7px' : 0)};
  `}

  &,
  &:focus {
    ${StyledInput} {
      outline-color: ${colors['sky-blue']};
    }
    ${StyledTextArea} {
      outline-color: ${colors['sky-blue']};
    }
  }
`

const ErrorMessage = styled.p`
  font-size: 15px;
  margin-bottom: 0;
  position: absolute;
  bottom: ${props => (props.textarea ? '-10px' : '-8px')};
  left: 6px;
  color: #ececec;
  ${media.xsMax`
    left: ${props => (props.textarea ? 0 : 'auto')};
    right: 6px;
  `}
`

const Input = React.forwardRef(({ label, type, id, error, textarea }, ref) => (
  <StyledLabel htmlFor={id} textarea={textarea}>
    {label}
    <StyledInput
      {...textarea && { as: StyledTextArea }}
      textarea={textarea}
      name={id}
      type={type}
      id={id}
      ref={ref}
      hasError={!!error}
    />
    {error && <ErrorMessage textarea={textarea}>{error.message}</ErrorMessage>}
  </StyledLabel>
))

Input.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  textarea: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  type: PropTypes.oneOf(['email', 'text'])
}

Input.defaultProps = {
  type: 'text',
  error: null,
  textarea: false,
}

export default Input
