import React from 'react'
import HoverIconContainer from './hoverIconContainer'
import Close from '../images/cerrar.svg'
import CloseHover from '../images/cerrar-hover.svg'
import Icon from '../images/siguiente.svg'
import {
  StyledContainer,
  StyledContainerLeft,
  StyledContainerRight,
  ContentIconClose,
  StyledContentText,
  InfoContent,
  SendContent as StyledContentVisitUs,
  StyledLocation,
  StyledProyectSend as StyledSeeAddress,
  IconNext,
  ContactText as VisitUsText,
} from './common'

const VisitUs = ({ isOpen, closeContact }) => {
  return (
    <StyledContainer isOpen={isOpen}>
      <StyledContainerLeft onClick={closeContact} />
      <StyledContainerRight>
        <ContentIconClose isOpen={isOpen} onClick={closeContact}>
          <HoverIconContainer Icon={Close} HoverIcon={CloseHover} />
        </ContentIconClose>
        <StyledContentText>
          <InfoContent>Nápoles, cdmx</InfoContent>
          <StyledContentVisitUs>
            <StyledLocation
              href="https://www.google.com.mx/maps/place/N%C3%A1poles,+Ciudad+de+M%C3%A9xico,+CDMX/@19.3936176,-99.1803593,16z/data=!3m1!4b1!4m5!3m4!1s0x85d1ff70fdea5055:0xf47485a18fe215a8!8m2!3d19.3943574!4d-99.1774462"
              target="_blank">
              <StyledSeeAddress>VER DIRECCIÓN</StyledSeeAddress>
              <IconNext as={Icon} />
            </StyledLocation>
            <VisitUsText>VISITÁNOS</VisitUsText>
          </StyledContentVisitUs>
        </StyledContentText>
      </StyledContainerRight>
    </StyledContainer>
  )
}

export default VisitUs
