import React from 'react'
import { graphql } from 'gatsby'
import { ParallaxProvider } from 'react-scroll-parallax'

import Layout from 'components/layout'
import Hero from 'components/hero'
import About from 'components/about'
import Services from 'components/services'
import Activities from 'components/activities'
import Contact from 'components/contact'

const IndexPage = ({ data }) => {
  const allImages = data.allImageSharp.nodes
  const homeBg = allImages.find(
    image => image.fluid.originalName === 'home.jpg'
  )
  const servicesBg = allImages.find(
    image => image.fluid.originalName === 'services.jpg'
  )

  return (
    <ParallaxProvider>
      <Layout>
        <Hero bg={homeBg.fluid} />
        <About />
        <Services bg={servicesBg.fluid} />
        <Activities />
        <Contact />
      </Layout>
    </ParallaxProvider>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allImageSharp(filter: { fluid: { originalName: { glob: "*.jpg" } } }) {
      nodes {
        fluid(quality: 100, maxWidth: 2400) {
          ...GatsbyImageSharpFluid_withWebp
          originalName
        }
      }
    }
  }
`
