import React from 'react'
import styled from 'styled-components'
import { Parallax } from 'react-scroll-parallax'
import { useMediaQuery } from 'react-responsive'

import {
  InnerContainer,
  Subtitle,
  Title,
  Info,
  Background,
} from 'components/common'
import { colors, media, sizes } from '../variables'

const DarkContainer = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 4rem;
  background-color: ${colors['dark-blue']};

  ${Info} {
    color: ${colors.white};
  }
`

const ImageContainer = styled.div`
  height: 40rem;
  max-height: 400px;
  background: transparent;
  overflow: hidden;
  position: relative;
`

const Square = styled.div`
  position: absolute;
  right: 30px;
  top: 0;
  width: 150px;
  height: 422px;
  right: 10vw;
  background: ${colors['sky-blue']};
  transform: skew(-10deg, 0deg);

  ${media.lgMax`
    display: none;
  `}
`

const Services = ({ bg }) => {
  const isMobile = useMediaQuery({
    query: `(max-device-width: ${sizes.md}px)`,
  })

  return (
    <>
      <DarkContainer id="service">
        <InnerContainer>
          <Subtitle>Servicios</Subtitle>
          <Title light>Los productos</Title>
          <Info>
            Los productos personalizados necesitan un conjunto de habilidades,
            por eso lo hacemos todo. Somos expertos en cada uno de los servicios
            que ofrecemos, desde la investigación del proyecto, la solución, el
            diseño, desarrollo, implementación, hasta el soporte. Confía en que
            tenemos cubierto todo lo que tu proyecto necesita.
          </Info>
        </InnerContainer>
        <Parallax
          disabled={isMobile}
          y={[-80, 55]}
          styleOuter={{ position: 'absolute', left: 0, right: 0, top: 0 }}
          styleInner={{
            position: 'relative',
            width: '100%',
            height: '40rem',
            maxHeight: '400px',
            overflow: 'hidden',
          }}>
          <Square />
        </Parallax>
      </DarkContainer>
      <ImageContainer>
        <Parallax
          disabled={isMobile}
          y={[0, -50]}
          styleOuter={{
            zIndex: -1,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          styleInner={{
            width: '100%',
            height: '100vh',
            overflow: 'hidden',
            zIndex: -1,
          }}>
          <Background fluid={bg} />
        </Parallax>
      </ImageContainer>
    </>
  )
}

export default Services
